<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
				<div class="login_title">{{ $t('adOrder') }}</div>
			</div>
			<div class="select_all">
				<div class="select_item" @click="selectIndex = 0">
					<div class="select_item_title" :class="selectIndex == 0 ? 'select_item_title_active' : ''">{{
						$t('order.all')
					}}</div>
					<!-- <div class="select_item_img">
						<img v-if="selectIndex==0" src="../../../../assets/home_icons/select.png">
					</div> -->
				</div>
				<div class="select_item" @click="selectIndex = 1">
					<div class="select_item_title" :class="selectIndex == 1 ? 'select_item_title_active' : ''">{{
						$t('order.obligation') }}</div>
					<!-- <div class="select_item_img">
						<img v-if="selectIndex==1" src="../../../../assets/home_icons/select.png">
					</div> -->
				</div>
				<div class="select_item" @click="selectIndex = 2">
					<div class="select_item_title" :class="selectIndex == 2 ? 'select_item_title_active' : ''">{{
						$t('order.finish') }}</div>
					<!-- <div class="select_item_img">
						<img v-if="selectIndex==2" src="../../../../assets/home_icons/select.png">
					</div> -->
				</div>
			</div>

			<div class="table_box">
				<div class="table_title">
					<div class="content">{{ $t('order.orderDetail') }}</div>
					<div class="money">{{ $t('money') }}</div>
					<div class="status">{{ $t('state') }}</div>
					<div class="check">{{ $t('operation') }}</div>
				</div>

				<div class="table_item" v-for="(item, index) in orders" :key="index">
					<div class="table_item_top">
						<div class="time">
							{{ item.create_time }}
						</div>
						<div>
							{{ $t('order.orderNo') }}：{{ item.order_sn }}
						</div>
					</div>
					<div class="table_item_center">
						<div class="content">
							<img :src="item.image_url" alt="" class="content_img">
							<div class="content_title" v-if="lang == 'zh'">{{ item.ads_type_nam }}</div>
							<div class="content_title" v-else>{{ item.ads_bnm_name }}</div>
						</div>
						<div class="money">
							<div class="price">${{ item.payable_money }}</div>
						</div>
						<div class="status">
							<!-- 状态;-1:取消已失效;0:待付款;1:已完成 -->
							<div class="status">
								<span v-if="item.status == -1">{{ $t('order.hasCancel') }}</span>
								<span v-if="item.status == 0">{{ $t('order.obligation') }}</span>
								<span v-if="item.status == 1">{{ $t('order.finish') }}</span>
							</div>
						</div>
						<div class="check">
							<button class="btn1" @click="goDetail(item.order_sn)">{{ $t('checkDetail') }}</button>
							<button class="btn1" v-if="item.status == 0"
								@click="cancelOrderClick(item.order_sn)">{{ $t('order.cancelOrder') }}</button>
							<button class="btn2" v-if="item.status == 0" @click="goDetail(item.order_sn)">{{ $t('order.toPay') }}</button>
						</div>
					</div>

				</div>
			</div>

			<!-- <div class="table">
				<el-table :data="orders" style="width: 100%" :header-cell-style="{ background: '#F6F7F9', }">
					<el-table-column type="index" label="" align="center">
					</el-table-column>
					订单
					<el-table-column width="180" prop="order_sn" :label="$t('order.orderNo')" align="center">
					</el-table-column>

					发布内容
					<el-table-column :label="$t('releaseContent')" width="180" align="center">
						<template slot-scope="scope">
							<div class="infor">
								<div class="infor_img">
									<img :src="scope.row.information_info.thumb_url[0].path">
								</div>
								<div class="infor_title">
									{{ scope.row.information_info.title }}
								</div>
							</div>
						</template>
					</el-table-column>

					时间
					<el-table-column prop="create_time" :label="$t('submitTime2')" align="center">
					</el-table-column>

					
					金额
					<el-table-column prop="payable_money" :label="$t('money')" align="center">
					</el-table-column>

					状态
					<el-table-column :label="$t('state')" align="center">
						<template slot-scope="scope">
							状态;-1:取消已失效;0:待付款;1:已完成
							<div class="status">
								<span class="blue" v-if="scope.row.status == -1">{{ $t('order.hasCancel') }}</span>
								<span class="gray" v-if="scope.row.status == 0">{{ $t('order.obligation') }}</span>
								<span class="red" v-if="scope.row.status == 1">{{ $t('order.finish') }}</span>
							</div>
						</template>
					</el-table-column>

					操作
					<el-table-column :label="$t('operation')" align="center">
						<template slot-scope="scope">
							<div class="operate">
								<span class="blue" @click="toOrderInforDetail(scope.row.order_sn)">{{ $t('checkDetail')
								}}</span>
								<el-popconfirm :title="$t('my.deleteAllContent')"
									@confirm="deleteOrderClick(scope.row.order_sn)" v-if="scope.row.status != 0">
									<span class="gray" slot="reference">{{ $t('delete') }}</span>
								</el-popconfirm>
								<span class="gray" @click="cancelOrderClick(scope.row.order_sn)"
									v-if="scope.row.status == 0">{{ $t('order.cancelOrder') }}</span>
								<span class="red" @click="toPayOrderClick(scope.row.order_sn)"
									v-if="scope.row.status == 0">{{ $t('order.toPay') }}</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div> -->



			<div class="pagination">
				<el-pagination background layout="prev, pager, next,sizes" :total="total"
					@current-change="currentChangeClick" @size-change="sizeChangeClick" :current-page="currentPage"
					:page-size="pageSize" background:true popper-class="select_bottom" :page-sizes="pageSizes">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			selectIndex: 0, //选中的

			orders: [], //订单列表
			total: 8, //总条数
			currentPage: 1, //当前页数
			pageSize: 8, //每页的条数
			pageSizes: [8, 10, 20, 30, 40, 50, 100],
			lang:''
		}
	},
	watch: {
		selectIndex() {
			this.currentPage = 1;
			this.getOrderList();
		}
	},
	created() {
    this.lang = localStorage.getItem("lang");

    // 实现监听localstorage中某个键对应的值的变化
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent", e => {
      //e.key : 是值发生变化的key
      //例如 e.key==="token";
      //e.newValue : 是可以对应的新值

      if (e.key === "lang") {
        this.lang = e.newValue;

        console.log(this.lang);
      }
    });
  },
	mounted() {
		this.getOrderList();
	},
	destroyed() {

	},
	methods: {
		// 获取订单信息
		getOrderList() {
			let status;
			switch (this.selectIndex * 1) {
				case 0:
					status = '';
					break;
				case 1:
					status = 0;
					break;
				case 2:
					status = 1;
					break;
			}
			// 状态;-1:取消已失效;0:待付款;1:已完成
			let data = {
				pageNo: this.currentPage,
				pageSize: this.pageSize,
				status,
				type: 2,
			}
			this.$http.orderList(data).then(res => {
				if (res.code == 1) {
					this.orders = res.data.data;
					this.total = res.data.total;

					console.log(this.orders,5555);
				} else {
					this.$message.info(res.msg);
				}
			})
		},
		// 去订单详情
		toOrderInforDetail(order_sn) {
			this.$router.push({
				path: '/adOrderDetail',
				query: {
					order_sn: order_sn,
				}
			})
		},


		currentChangeClick(currentPage) {
			this.currentPage = currentPage;
			this.getOrderList();

		},
		sizeChangeClick(pageSize) {
			this.pageSize = pageSize;
			this.getOrderList();
		},

		// 取消订单
		cancelOrderClick(order_sn) {
			this.$http.cancelOrder({
				order_sn: order_sn,
				type: 2,
			}).then(res => {
				if (res.code == 1) {
					this.$message.success(res.msg);
					this.getOrderList();
				} else {
					this.$message.error(res.msg);
				}
			})

		},

		// 去支付
		toPayOrderClick(order_sn) {
			this.$http.getPayPal({
				order_sn: order_sn,
			}).then(res => {
				if (res.code == 1) {
					let payUrl = res.data;
					window.open(payUrl);
				}
			})
		},

		// 删除订单
		deleteOrderClick(order_sn) {
			this.$http.orderDel({
				order_sn: order_sn,
			}).then(res => {
				if (res.code == 1) {
					this.$message.success(res.msg);
					this.getOrderList();
				} else {
					this.$message.error(res.msg);
				}
			})
		},

		goDetail(order_sn) {
			this.$router.push(
				{
					path: `/adOrderDetail`,
					query: {
						order_sn,

					}
				}
			)
		},
	}
}
</script>

<style scoped lang="less">
.person_infor {
	// width: 926px;
}


.login_top {
	display: flex;
	align-items: center;
	padding: 24px 40px;
	border-bottom: 1px solid #F3F5F6;


	.img {
		display: flex;
		align-items: center;

		img {
			width: 18px;
			height: 18px;
		}
	}

	.login_title {
		margin-left: 8px;
		font-weight: bold;
		font-size: 18px;

		&:hover {
			cursor: pointer;
		}
	}

	.front_img {
		display: flex;
		align-items: center;
		margin: 0 12px;

		img {
			width: 8px;
			height: 14px;
		}
	}
}

.select_all {
	display: flex;
	padding: 24px 0 24px 40px;

	.select_item {
		margin-right: 64px;
		cursor: pointer;

		.select_item_title {
			font-size: 16px;
			width: 120px;
			height: 40px;
			background: #F6F7F9FF;
			border-radius: 4px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			line-height: 40px;
			color: #333333;
		}

		.select_item_title_active {
			background: rgba(243, 152, 0);
			color: #FFFFFF;
		}


		.select_item_img {
			margin: 0 auto;
			width: 32px;
			height: 4px;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}

.table_box {
	padding: 0 40px;

	.table_title {
		display: flex;
		font-size: 14px;
		color: #333;
		align-items: center;
		border: 1px solid #CECECE;
		height: 50px;
		line-height: 50px;


		.content {
			width: 633px;
			height: 100%;
			border-right:  1px solid #CECECE
		}

		.money {
			width: 250px;
			height: 100%;
			border-right:  1px solid #CECECE
		}

		.status {
			width: 250px;
			height: 100%;
			border-right:  1px solid #CECECE
		}

		.check {
			width: 340px;
		}
	}

	.table_item {
		.table_item_top {
			height: 40px;
			background-color: #F6F7F9;
			display: flex;
			align-items: center;
			margin-top: 16px;
			font-size: 12px;
			color: #333333;

			.time {
				margin: 0 48px 0 16px;
				font-weight: bold;
			}
		}

		.table_item_center {
			display: flex;

			.content {
				display: flex;
				justify-content: left;
				align-items: center;
				padding: 0 16px;
				width: 633px;
				height: 112px;
				border: 1px solid #E0E0E0;
				box-sizing: border-box;


				.content_img {
					width: 80px;
					height: 80px;
					margin-right: 24px;
				}

				.content_title {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
			}

			.money {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 250px;
				height: 112px;
				border: 1px solid #E0E0E0;
				box-sizing: border-box;


				.price {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
				}
			}

			.status {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 250px;
				height: 112px;
				border: 1px solid #E0E0E0;
				box-sizing: border-box;


				span {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					line-height: 17px;
					color: #333333;
				}
			}

			.check {
				padding: 10px 62px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				flex: 1;
				width: 250px;
				height: 112px;
				border: 1px solid #E0E0E0;
				box-sizing: border-box;


				.btn1 {
					width: 88px;
					height: 32px;
					background: #F6F7F9;
					border: 1px solid #DDDDDD;
					border-radius: 4px;
				}

				.btn2 {
					width: 88px;
					height: 32px;
					background: #F39800;
					border-radius: 4px;
					color: #fff;
					border: none;
				}
			}
		}
	}
}

.table {
	padding: 0 40px;

	.infor {
		display: flex;
		align-items: center;

		.infor_img {
			img {
				width: 48px;
				height: 48px;
			}
		}

		.infor_title {
			margin-left: 8px;
			width: 132;
			height: 45px;
			color: #333333;
			font-weight: 500;
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
			word-break: break-all;
			word-wrap: break-word;
		}
	}

	.status {
		font-size: 12px;

		&>span:hover {
			cursor: pointer;
		}

		.red {
			color: #F39800;
		}

		.blue {
			color: #4177DD;
		}

		.gray {
			color: #999999;
		}

	}

	.operate {
		span {
			font-size: 12px;
			display: inline-block;

			&:hover {
				cursor: pointer;
			}
		}

		.blue {
			margin-right: 8px;
			color: #4177DD;
		}

		.gray {
			color: #999999;
		}

		.red {
			margin-left: 8px;
			color: #F39800;
		}
	}
}


.pagination {
	margin-top: 32px;
	margin-right: 20px;
	display: flex;
	justify-content: flex-end;
}

/deep/ .el-table .cell {
	text-overflow: clip !important;
	word-break: normal;
}

.select {
	color: #F39800;
	font-weight: bold;
}

.un_select {
	color: #666666;
	font-weight: 400;
}
</style>
